<!--
 * @Author: your name
 * @Date: 2021-05-12 14:26:50
 * @LastEditTime: 2021-05-16 18:55:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\addskill2\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation></navigation>
    <div class="theclass">
      <div class="jincl">技能名称</div>
      <div class="skillna">
        <input
          v-model="customizename"
          type="text"
          placeholder="请输入技能名称"
          name=""
          id=""
        />
      </div>
      <button @click="tozidy" class="btonm">确认</button>
      <myalert @nometh="showalert = false" @okmeth="okmeth" :show="showalert">
        <div class="thealeclass">
          <img class="theimg" src="../../assets/tan.png" alt="" />
          <div class="themsgcc">
            <div>是否确认新增技能</div>
          </div>
        </div>
      </myalert>
    </div>
  </div>
</template>
<script>
import myalert from "../../components/myalert/index";
export default {
  components: {
    myalert,
  },
  data() {
    return {
      showalert: false,
      customizename: "", //自定义名称
    };
  },
  methods: {
    okmeth() {
      this.$http
        .post("/firm/v1/Skillme/positionAdd", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
          position_name: this.customizename,
          mim_id: localStorage.getItem("userId"),
        })
        .then((res) => {
           
          this.$toast("新增成功");
          if (sessionStorage.getItem("profilemsg_msg") == "true") {
            sessionStorage.removeItem("profilemsg_msg");
            this.$router.go(-1);
          } else {
            this.$router.go(-2);
          }
        });
    },
    tozidy() {
      if (this.customizename == "") {
        this.$toast("请输入自定义技能名称");
        return;
      }
      this.showalert = true;
    },
  },
};
</script>
<style scoped>
.themsgcc {
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #333333;
}
.thealeclass {
  text-align: center;
  margin: 0 0.25rem;
  margin-top: 0.62rem;
  margin-bottom: 0.8rem;
}
.theimg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.3rem;
}
.btonm {
  width: 100%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  padding: 0.28rem 0;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ffffff;
  border: none;
  border-radius: 0.16rem;
  margin-top: 3rem;
}
.skillna {
  padding: 0.23rem 0;
  margin-top: 0.09rem;
  font-size: 0.28rem;
  line-height: 0.48rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #bfbfbf;
}
.skillna input {
  border: none;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.jincl {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.theclass {
  margin: 0.3rem 0.48rem;
}
</style>
